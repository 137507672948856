
import { mdiFacebook, mdiInstagram } from '@mdi/js'
export default {
  data() {
    return {
      socials: [
        {
          name: 'Pen The Journey',
          icon: mdiFacebook,
          link: 'https://www.facebook.com/penthejourney/',
        },
        {
          name: '@penthejourney',
          icon: mdiInstagram,
          link: 'https://www.instagram.com/penthejourney/',
        },
      ],
    }
  },
  props: {
    drawer: Boolean,
    routes: Array,
  },
  methods: {
    l(event) {
      if (!event) this.$emit('closeDrawer')
    },
  },
}
